import React, {useContext} from 'react'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GlobalStateContext } from "../context/GlobalContextProvider"

function Contact({location, data}){
    const siteTitle = data.site.siteMetadata.title
    const state = useContext(GlobalStateContext)
    const { success } = state.language    

    return(
        <Layout location={location} title={siteTitle}>
            <SEO title="Contato" />
            <section className="section-contact">
                <div className="wrapper fullHeight" data-grid="center">
                    <div className="center">
                        <h2>{success.title}</h2>
                        <p>{success.text}</p>
                        <section data-grid="small-spacing row center">
                            <Link data-cell="shrink" data-btn to="/blog">{success.portfolio}</Link>
                            <Link data-cell="shrink" data-btn to="/contact">{success.again}</Link>
                        </section>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`